<template>
    <div>
      <b-modal
        size="custom-lg-max"
        id="modal-interconsulta"
      >
        <template #modal-header>
        <H4><b>Importe para InterConsulta</b> </H4>
        </template>
        <div>
        <div class="row">
            <div class="col" :class="{ 'bg-warning': selectedOption === 'individual' }"
            @click="selectOption('individual')">  <!-- Agregar el evento click aquí -->
                <label>
                <input
                    type="radio"
                    name="opciones"
                    value="individual"
                    v-model="selectedOption"
                />
                <b> Atencion Individual</b>
                </label>
                <div class="row p-3 mb-3 mx-2" style="border-radius: 5px; border: 1px solid orange;">
                    <div class="col">
                        <label for="">Paciente Particular</label>
                        <input type="text" class="form-control" placeholder="" />
                    </div>
                    <div class="col">
                        <label for="">Paciente C/Seguro</label>
                        <input type="text" class="form-control" placeholder="" />
                    </div>
                </div>
            </div>
            <div class="col" :class="{ 'bg-warning': selectedOption === 'dia' }"
            @click="selectOption('dia')">  <!-- Agregar el evento click aquí -->
                <label>
                <input
                    type="radio"
                    name="opciones"
                    value="dia"
                    v-model="selectedOption"
                />
                <b> Atencion por Dia</b>
                </label>
                <div class="row p-3 mb-3 mx-2" style="border-radius: 5px; border: 1px solid orange;">
                    <div class="col">
                      <label for="">Paciente Particular</label>
                      <input type="text" class="form-control" placeholder="" />
                    </div>
                    <div class="col">
                        <label for="">Paciente C/Seguro</label>
                      <input type="text" class="form-control" placeholder="" />
                    </div>
                </div>
            </div>
            </div>
        </div>
        <template #modal-footer>
            <div class="d-flex justify-content-between align-items-center w-100">
                <label class="watermark" for="">Esta Funcion es para Internacion</label>
                <div>
                    <button class="btn btn-secondary mr-2" @click="closeModal">Cancelar</button>
                    <button class="btn btn-success" @click="saveAndClose">Guardar</button>
              </div>
            </div>
          </template>
      </b-modal>
    </div>
  </template>
<script>

export default {
  name: 'TheModalInterConsulta',
  data() {
    return {
      selectedOption: null,
    };
  },
  methods: {
    selectOption(option) {
      this.selectedOption = option; // Cambia el valor de selectedOption al que se pasó como argumento
    },
    closeModal() {
      this.$bvModal.hide('modal-interconsulta'); // Cierra el modal
    },
    saveAndClose() {
      this.closeModal();
    },
  },
};
</script>

<style>
.bg-warning {
    background-color: rgb(255, 255, 203) !important;
  }
  .watermark {
    color: #999;
    opacity: 0.5;
    pointer-events: none;
    margin-top: 10px;
  }
</style>
