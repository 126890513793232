<template>
  <div>
    <b-modal
    size="custom-lg-max"
    id="modal-comisiones"
    hide-footer
    >
    <template #modal-header="{ close }">
      <div class="col-sm-12">
        <div class="form-group row">
          <div class="col-sm-2 ml-auto">
            <label class="switch switch-success mr-3">
              <input
                type="checkbox"
                id="filter"
                :checked="guardadoMasivo"
                v-model="guardadoMasivo"
                @change="handleTipoGuardado"
              >
              <span class="slider"></span>
              <span>{{ labelGuardado }}</span>
            </label>
          </div>
          <div class="col-sm-1 text-right">
            <span class="font-weight-bold align-middle" @click="close()"
              style="cursor: pointer">X</span>
          </div>
        </div>
      </div>
    </template>
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group row">
            <div class="col-sm-2 px-0">
              <div class="form-group" v-if="guardadoMasivo">
                <label for="tipo_servicio" style="font-size: 0.9em;">Categoria de Servicio</label>
                <multiselect2
                  v-model="tipoCategoriaSelected"
                  track-by="id"
                  label="nombre"
                  placeholder="Seleccione"
                  :options="categoriaServicios"
                  :searchable="true"
                ></multiselect2>
              </div>
              <div class="form-group" v-else>
                <label for="tipo_servicio" style="font-size: 0.9em;">Tipo de Servicio</label>
                <multiselect2
                  v-model="tipoServicio"
                  track-by="id"
                  label="nombre"
                  placeholder="Seleccione"
                  :options="listaServicios"
                  :searchable="true"
                  @select="selectConsulta"
                ></multiselect2>
              </div>
            </div>
            <div class="col-sm-2">
              <label style="font-size: 0.9em;">Tipo de Comision</label>
              <multiselect2
                v-model="tipoComisionSelected"
                track-by="id"
                label="nombre"
                placeholder="Comision"
                :options="tipoComisions"
                :allow-empty="false"
                :searchable="true"
                @select="selectComisiones"
              ></multiselect2>
            </div>
            <div class="col-sm-2 pl-0">
              <div class="form-group">
                <label for="re_consulta" style="font-size: 0.9em;">ReConsulta</label>
                <multiselect2
                  v-model="tipoReconsultaSelected"
                  track-by="id"
                  label="nombre"
                  placeholder="Seleccione"
                  :options="tipoReconsultas"
                  :searchable="true"
                  @select="selectConsulta"
                ></multiselect2>
              </div>
            </div>
            <div class="col-sm-1 px-0">
              <div class="form-group">
                  <label for="tiempo_validez" style="font-size: 0.9em;">Tiempo/Dias</label>
                  <input type="text" class="form-control"
                          v-model="tiempoValidez"
                          autocomplete="off" @keydown="validarCaracter" @blur="reValidarTiempoValidez"
                          :disabled="tipoReconsultaSelected && tipoReconsultaSelected.nombre === 'No'">
              </div>
            </div>
            <div class="col-sm-2">
              <label style="font-size: 0.9em;">Tipo de Calculo</label>
              <multiselect2
                v-model="tipoFacturaSelected"
                track-by="id"
                label="nombre"
                placeholder="Seleccione"
                :options="tipoFacturas"
                :searchable="true"
                :disabled="isTipoComisionNoAplica"
              ></multiselect2>
            </div>
            <div class="col-sm-1 pl-0">
              <label for="tratante"  style="font-size: 0.8em;">Tratante{{ getSimbolFromComision }}</label>
              <div class="form-group">
                  <vue-numeric class="form-control"
                  v-model="tratante"
                  separator=","
                  :precision="2"
                  placeholder=""
                  :disabled="!tipoComisionSelected || isTipoComisionNoAplica"
                  />
              </div>
            </div>
            <div class="col-sm-1 pl-0">
              <div class="form-group">
                <label for="referido" style="font-size: 0.7em;">Derivador{{ getSimbolFromComision }}</label>
                <vue-numeric class="form-control"
                  v-model="referido"
                  separator=","
                  :precision="2"
                  placeholder=""
                  :disabled="!tipoComisionSelected || isTipoComisionNoAplica"
                />
              </div>
            </div>
            <div class="col-sm-1 pl-0">
              <div class="form-group">
                <label for=""></label>
                <button class="btn btn-success"
                  type="button"
                  @click="agregarItem"
                >
                  Agregar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <b-table
          class="table table-striped table-hover"
          :fields="fields"
          :items="getListaComisionesActivos"
        >
          <template #cell(numero)="item">
            {{ listaComisiones.length - item.index }}
          </template>
          <template #cell(reconsulta_detallado)="item">
            {{ item.item.reconsulta_detallado }}
            <svg
              v-if="item.index === 0"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-lightning-fill text-warning accion-rapida"
              viewBox="0 0 16 16">
                <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1
                .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"/>
            </svg>
          </template>
          <template #cell(comision)="item">
            {{ item.item.comision }}
          </template>
          <template #cell(tratante)="item">
            {{ item.item.tratante }}  <span v-if="item.item.tratante !== null">
              {{ item.item.comision_simbolo }}
            </span>
            <svg
              v-if="item.index === 0"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-lightning-fill text-warning accion-rapida"
              viewBox="0 0 16 16">
                <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1
                .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"/>
            </svg>
          </template>
          <template #cell(calculo)="item">
            {{ item.item.funcion }}
          </template>
          <template #cell(referido)="item">
            {{ item.item.referido }} <span v-if="item.item.referido !== null">
              {{ item.item.comision_simbolo }}
            </span>
            <svg
              v-if="item.index === 0"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-lightning-fill text-warning accion-rapida"
              viewBox="0 0 16 16">
                <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1
                .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"/>
            </svg>
          </template>
          <template #cell(eliminar)="row">
            <i
              class="nav-icon i-Close-Window font-weight-bold text-danger"
              style="cursor: pointer"
              title="Eliminar"
              @click="eliminarItemServicioComisiones(row.item, row.index);"
            ></i>
          </template>
          <!-- <template #cell(editar)>
            <i
              class="nav-icon i-Pen-5 font-weight-bold text-success"
              style="cursor: pointer"
            ></i>
          </template> -->
        </b-table>
      </div>
    </div>
    </b-modal>
  </div>
</template>

<script>
import Multiselect2 from '@/components/util/vue-multiselect2';
import VueNumeric from 'vue-numeric';
import { util } from '@/plugins/util';
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'TheModalConsultaExterna',
  props: {
    monedaPrincipal: {
      required: true,
    },
  },
  data() {
    return {
      isNoAplica: false,
      siglaTipoComision: null,
      guardadoMasivo: false,
    };
  },
  components: {
    Multiselect2,
    VueNumeric,
  },
  created() {
    this.disabledServiciosInComisiones();
  },
  methods: {
    selectComisiones(item) {
      this.isNoAplica = false;
      this.siglaTipoComision = null;
      switch (item.id) {
        case 1:
          this.siglaTipoComision = 'Bs';
          break;
        case 2:
          this.siglaTipoComision = '%';
          break;
        case 3:
          this.isNoAplica = true;
          this.tratante = 0;
          this.referido = 0;
          break;
        default:
          break;
      }
    },
    agregarItem() {
      if (this.validar()) {
        let item = null;
        if (this.guardadoMasivo) {
          this.getServicesListByCategory();
        } else {
          const tiempoValidezInput = this.tiempoValidez > 0 ? `${this.tiempoValidez} - días` : '';
          item = {
            id: 0,
            funcion: this.tipoFacturaSelected ? this.tipoFacturaSelected.nombre : null, // Maneja el caso en que tipoFacturaSelected sea null
            categoria: this.tipoServicio.nombre_categoria,
            categoria_id: this.tipoServicio.categoria_id,
            servicio: this.tipoServicio.nombre,
            tipo_servicio_id: this.tipoServicio.id,
            reconsulta_detallado: `${this.tipoReconsultaSelected.nombre === 'Si' ? `${this.tipoReconsultaSelected.nombre} ${tiempoValidezInput}` : this.tipoReconsultaSelected.nombre}`,
            reconsulta: (this.tipoReconsultaSelected.nombre === 'Si' ? 1 : 0),
            tiempo_validez: this.tiempoValidez,
            tratante: this.tratante === 0 ? null : this.tratante,
            referido: this.referido === 0 ? null : this.referido,
            comision_id: this.tipoComisionSelected.id,
            funcion_id: this.tipoFacturaSelected?.id ?? '', // Usa null si tipoFacturaSelected es null o undefined
            comision: this.tipoComisionSelected.nombre,
            comision_simbolo: this.siglaTipoComision,
            estado: true,
          };
          // validar que no exista el item seleccionar en la listaComisiones
          const IS_VALIDO_SERVICIO = this.validarTipoServiciosInComisiones(item.tipo_servicio_id);
          if (IS_VALIDO_SERVICIO) {
            this.listaComisiones.push(item);
          }
        }
        this.limpiar();
      }
    },
    getServicesListByCategory() {
      if (this.tipoCategoriaSelected === null) {
        util.showNotify('Seleccione un tipo de categoria', 'warn');
        return;
      }
      const idCategory = this.tipoCategoriaSelected.id;
      const listTemp = this.listaServicios.filter((el) => el.categoria_id === idCategory);
      listTemp.forEach((el) => {
        const tiempoValidezInput = this.tiempoValidez > 0 ? `${this.tiempoValidez} - días` : '';
        const item = {
          id: 0,
          funcion: this.tipoFacturaSelected ? this.tipoFacturaSelected.nombre : null, // Maneja el caso en que tipoFacturaSelected sea null
          categoria: el.nombre_categoria,
          categoria_id: el.categoria_id,
          servicio: el.nombre,
          tipo_servicio_id: el.id,
          reconsulta_detallado: `${this.tipoReconsultaSelected.nombre === 'Si' ? `${this.tipoReconsultaSelected.nombre} ${tiempoValidezInput}` : this.tipoReconsultaSelected.nombre}`,
          reconsulta: (this.tipoReconsultaSelected.nombre === 'Si' ? 1 : 0),
          tiempo_validez: this.tiempoValidez,
          tratante: this.tratante === 0 ? null : this.tratante,
          referido: this.referido === 0 ? null : this.referido,
          comision_id: this.tipoComisionSelected.id,
          funcion_id: this.tipoFacturaSelected?.id ?? '', // Usa null si tipoFacturaSelected es null o undefined
          comision: this.tipoComisionSelected.nombre,
          comision_simbolo: this.siglaTipoComision,
          estado: true,
        };
        // validar antes que el servicio no se repita
        const IS_VALIDO_SERVICIO = this.validarTipoServiciosInComisiones(item.tipo_servicio_id);
        if (IS_VALIDO_SERVICIO) {
          this.listaComisiones.push(item);
        }
      });
    },
    handleTipoGuardado() {
      if (this.guardadoMasivo) {
        this.tipoServicio = null;
      } else {
        this.tipoCategoriaSelected = null;
      }
    },
    limpiar() {
      this.tipoCategoriaSelected = null;
      this.tipoServicio = null;
      this.tipoReconsultaSelected = null;
      this.tiempoValidez = 0;
      /* this.tratante = 0;
      this.referido = 0; */
    },
    validarCaracter(event) {
      const validKeys = new RegExp('^[0-9]*$');
      const controlKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'KeyX', 'KeyC', 'KeyV', 'Home', 'End', 'Tab'];
      if (controlKeys.includes(event.code)) {
        switch (event.code) {
          case 'KeyX':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyC':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          case 'KeyV':
            if (!event.ctrlKey) {
              event.preventDefault();
            }
            break;
          default:
            break;
        }
        return;
      }
      if (!validKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    reValidarTiempoValidez() {
      if (this.tiempoValidez === '') {
        return;
      }
      const validKeys = new RegExp('^[0-9]*$');
      if (!validKeys.test(this.tiempoValidez)) {
        this.tiempoValidez = '';
      }
    },
    validar() {
      let valido = true;
      if (this.guardadoMasivo) {
        if (!this.tipoCategoriaSelected) {
          util.showNotify('Seleccione un tipo de categoria', 'warn');
          valido = false;
        }
      } else if (!this.tipoServicio) {
        util.showNotify('Seleccione un tipo de servicio', 'warn');
        valido = false;
      }
      /*  if (!this.tipoFacturaSelected) {
        util.showNotify('Seleccione un tipo de función', 'warn');
        valido = false;
      } */
      if (!this.tipoReconsultaSelected) {
        util.showNotify('Seleccione un tipo de reconsulta', 'warn');
        valido = false;
      }
      if (!this.tipoComisionSelected) {
        util.showNotify('Seleccione un tipo de comision', 'warn');
        valido = false;
      }

      return valido;
    },
    selectConsulta(item) {
      if (item.id === 2) {
        // tipo reconsulta NO
        this.tiempoValidez = 0;
      }
    },
    validarTipoServiciosInComisiones(tipoServicioId) {
      let valido = true;
      this.getListaComisionesActivos.forEach((el, index) => {
        if (this.getListaComisionesActivos[index].tipo_servicio_id === tipoServicioId) {
          util.showNotify(`El servicio ${el.servicio} ya se encuentra en la lista`, 'warn');
          valido = false;
        }
      });

      // bloquear en listaServicios el item agregado
      const INDEX_SERVICIO = this.listaServicios.findIndex((el) => (
        el.id === tipoServicioId
      ));
      this.listaServicios[INDEX_SERVICIO].$isDisabled = true;
      return valido;
    },
    eliminarItemServicioComisiones(item, index) {
      this.getListaComisionesActivos[index].estado = false;
      const INDEX_SERVICIO = this.listaServicios.findIndex((el) => (
        el.id === item.tipo_servicio_id
      ));
      this.listaServicios[INDEX_SERVICIO].$isDisabled = false;
    },
    disabledServiciosInComisiones() {
      this.listaComisiones.forEach((el) => {
        this.listaServicios.forEach((el2, index2) => {
          if (el.tipo_servicio_id === el2.id) {
            this.listaServicios[index2].$isDisabled = true;
          }
        });
      });
    },
  },
  computed: {
    ...mapState('modalComisiones', [
      'fields',
      'tipoReconsultas',
      'categoriaServicios',
    ]),
    ...mapFields('modalComisiones', [
      'listaComisiones',
      'tipoFacturaSelected',
      'tipoCategoriaSelected',
      'tipoComisionSelected',
      'tipoReconsultaSelected',
      'tipoServicio',
      'tiempoValidez',
      'tratante',
      'referido',
      'tipoFacturas',
      'tipoComisions',
    ]),
    ...mapFields('modalHonorarioMedico', [
      'listaServicios',
    ]),
    isTipoComisionNoAplica() {
      if (this.tipoComisionSelected != null) {
        return (this.tipoComisionSelected.id === 3);
      }
      return false;
    },
    getSimbolFromComision() {
      if (this.tipoComisionSelected != null) {
        if (this.tipoComisionSelected.id === 1) {
          return `-${this.monedaPrincipal.simbolo}`;
        }
        if (this.tipoComisionSelected.id === 2) {
          return '-%';
        }
      }
      return '';
    },
    labelGuardado() {
      if (this.guardadoMasivo) {
        return 'Masivo';
      }
      return 'Individual';
    },
    getListaComisionesActivos() {
      const comisionesActivas = this.listaComisiones.filter((item) => (
        item.estado === true
      ));
      return comisionesActivas.reverse();
    },
  },
};
</script>

<style>
.th-custom-gris {
  background-color: #cdcdcd;
}
</style>
