<template>
  <b-modal id="modal-honorarioMedico"
    size='lg'
    hide-footer
  >
    <template #modal-header="{ close }">
      <h5 class="modal-title">
        Honorario Médico
        <span class="ml-3" style="font-size: 70%">
          (Esto es para Internación, para un medico externo)
        </span>
      </h5>
      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="close()"
      >×</button>
    </template>
    <div class="row mb-2">
      <div class="col-sm-3">
        <div class="form-group">
          <label>Servicio:</label>
          <multiselect
            v-model="servicioSelected"
            :options="listaServicios"
            placeholder=""
            label="nombre" track-by="id"
            select-label=""
            deselect-label="X">
            <span slot="caret" v-if="!(servicioSelected===null)"></span>
            <span slot="noResult">Sin Resultados</span>
            <span slot="noOptions">Lista Vacia</span>
            <template slot="singleLabel" slot-scope="props">
              <small>{{props.option.nombre}}</small>
            </template>
            <template slot="option" slot-scope="props">
              <small>{{props.option.nombre}}</small>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="col-sm-3 pl-0">
        <div class="form-group">
          <label>Categoria:</label>
          <multiselect
            v-model="categoriaSelected"
            :options="listaCategorias"
            placeholder=""
            label="nombre" track-by="id"
            select-label=""
            deselect-label="X">
            <span slot="caret" v-if="!(categoriaSelected===null)"></span>
            <span slot="noResult">Sin Resultados</span>
            <span slot="noOptions">Lista Vacia</span>
            <template slot="singleLabel" slot-scope="props">
              <small>{{props.option.nombre}}</small>
            </template>
            <template slot="option" slot-scope="props">
              <small>{{props.option.nombre}}</small>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="col-sm-2 pl-0">
        <div class="form-group">
          <label>P/Costo:</label>
          <vue-numeric class="form-control"
            v-model="precioCosto"
            separator=","
            :precision="2"
            placeholder=""
          />
        </div>
      </div>
      <div class="col-sm-2 pl-0">
        <div class="form-group">
          <label>P/Paciente:</label>
          <vue-numeric class="form-control"
            v-model="precioPaciente"
            separator=","
            :precision="2"
            placeholder=""
          />
        </div>
      </div>
      <div class="col-sm-2 pt-3">
        <button
          class="btn btn-warning"
          @click="onClickConfirmButton"
        >
          {{modoEdicion? 'Modificar':'Agregar'}}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <b-table responsive class="table table-striped table-bordered table-hover"
          :fields="fieldsTabla"
          :items="tablaHonorarioMedicoFiltered"
        >
          <template #cell(editar)="data">
            <i
              class="nav-icon i-Pen-5 text-success font-weight-bold"
              style="cursor: pointer"
              @click="editarItem(data.item)"
            ></i>
          </template>
          <template #cell(eliminar)="data">
            <i
              class="nav-icon i-Close-Window text-danger font-weight-bold"
              style="cursor: pointer"
              @click="deleteItem(data.item)"
            ></i>
          </template>
          <template #cell(precio_costo)="data">
            {{data.item.precio_costo | numeral('0,0.00')}}
          </template>
          <template #cell(precio_paciente)="data">
            {{data.item.precio_paciente | numeral('0,0.00')}}
          </template>
        </b-table>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import VueNumeric from 'vue-numeric';
import { util } from '@/plugins/util';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'ModalHonorarioMedico',
  components: {
    Multiselect,
    VueNumeric,
  },
  data() {
    return {
      fieldsTabla: [
        {
          key: 'editar', label: ' ', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'eliminar', label: ' ', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'numero', label: 'ID', thStyle: { width: '10%' },
        },
        {
          key: 'servicio', label: 'Servicio', class: 'text-center', thStyle: { width: '27%' },
        },
        {
          key: 'categoria', label: 'Categoria', class: 'text-center', thStyle: { width: '27%' },
        },
        {
          key: 'precio_costo', label: 'P/Costo', thStyle: { width: '15%' },
        },
        {
          key: 'precio_paciente', label: 'P/Paciente', thStyle: { width: '15%' },
        },
      ],
      modoEdicion: false,
      selectedCustomId: null,
    };
  },
  methods: {
    limpiarCampos() {
      this.servicioSelected = null;
      this.categoriaSelected = null;
      this.precioCosto = 0;
      this.precioPaciente = 0;
    },
    modificarItem() {
      const LISTA_VERIFICACION = this.tablaHonorarioMedico.filter((el) => (
        el.tipo_servicio_id === this.servicioSelected.id
        && el.categoria_medico_id === this.categoriaSelected.id
      ));
      const INDEX = this.tablaHonorarioMedico.findIndex((el) => (
        el.customId === this.selectedCustomId
      ));
      const TS_ID = this.tablaHonorarioMedico[INDEX].tipo_servicio_id;
      const CM_ID = this.tablaHonorarioMedico[INDEX].categoria_medico_id;
      if (TS_ID === this.servicioSelected.id && CM_ID === this.categoriaSelected.id) {
        //
      } else if (LISTA_VERIFICACION.length > 0) {
        util.showNotify('La categoria ya fue asignada, Elija otra.', 'warn');
        return;
      }
      this.tablaHonorarioMedico[INDEX].tipo_servicio_id = this.servicioSelected.id;
      this.tablaHonorarioMedico[INDEX].servicio = this.servicioSelected.nombre;
      this.tablaHonorarioMedico[INDEX].categoria_medico_id = this.categoriaSelected.id;
      this.tablaHonorarioMedico[INDEX].categoria = this.categoriaSelected.nombre;
      this.tablaHonorarioMedico[INDEX].precio_costo = this.precioCosto;
      this.tablaHonorarioMedico[INDEX].precio_paciente = this.precioPaciente;
      this.limpiarCampos();
      this.selectedCustomId = null;
      this.modoEdicion = false;
    },
    agregarItem() {
      const LISTA_VERIFICACION = this.tablaHonorarioMedico.filter((el) => (
        el.tipo_servicio_id === this.servicioSelected.id
        && el.categoria_medico_id === this.categoriaSelected.id
      ));
      if (LISTA_VERIFICACION.length > 0) {
        util.showNotify('La categoria ya fue asignada, Elija otra.', 'warn');
        return;
      }
      const ITEM = {
        id: 0,
        customId: new Date().getTime(),
        numero: '-',
        tipo_servicio_id: this.servicioSelected.id,
        servicio: this.servicioSelected.nombre,
        categoria_medico_id: this.categoriaSelected.id,
        categoria: this.categoriaSelected.nombre,
        precio_costo: this.precioCosto,
        precio_paciente: this.precioPaciente,
        estado: true,
      };
      this.tablaHonorarioMedico.push(ITEM);
      this.limpiarCampos();
    },
    onClickConfirmButton() {
      if (this.servicioSelected === null) {
        util.showNotify('Elija un Servicio.', 'warn');
        return;
      }
      if (this.categoriaSelected === null) {
        util.showNotify('Elija una Categoria.', 'warn');
        return;
      }
      if (this.modoEdicion) {
        this.modificarItem();
      } else {
        this.agregarItem();
      }
    },
    editarItem(item) {
      this.selectedCustomId = item.customId;
      this.modoEdicion = true;
      this.servicioSelected = this.listaServicios.find((el) => el.id === item.tipo_servicio_id);
      this.categoriaSelected = this.listaCategorias.find((el) => (
        el.id === item.categoria_medico_id
      ));
      this.precioCosto = item.precio_costo;
      this.precioPaciente = item.precio_paciente;
    },
    deleteItem(item) {
      if (this.modoEdicion) {
        this.limpiarCampos();
        this.selectedCustomId = null;
        this.modoEdicion = false;
      }
      const NRO = item.numero;
      if (NRO === '-') {
        const INDEX = this.tablaHonorarioMedico.findIndex((el) => el.customId === item.customId);
        this.tablaHonorarioMedico.splice(INDEX, 1);
      } else {
        const INDEX = this.tablaHonorarioMedico.findIndex((el) => el.customId === item.customId);
        this.tablaHonorarioMedico[INDEX].estado = false;
      }
    },
  },
  computed: {
    tablaHonorarioMedicoFiltered() {
      return this.tablaHonorarioMedico.filter((item) => item.estado === true);
    },
    ...mapFields('modalHonorarioMedico', [
      'tablaHonorarioMedico',
      'listaServicios',
      'listaCategorias',
      'servicioSelected',
      'categoriaSelected',
      'precioCosto',
      'precioPaciente',
    ]),
  },
};
</script>

<style scoped>
</style>
